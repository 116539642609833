<template>
  <div class="container">
    <div class="t-tabs">
      <AppTabs v-model="selectedTabIdx" :tabs="tabs">
        <template #tabs-content-list>
          <span v-for="tab in tabs" :key="tab.id">
            {{ tab.value }}
          </span>
        </template>
      </AppTabs>
    </div>
    gas;gjasklgjlk
  </div>
</template>

<script>
import AppTabs from "@/components/AppTabs/AppTabs";
export default {
  name: "Test",
  components: { AppTabs },
  data() {
    return {
      selectedTabIdx: 0,
      tabs: [
        {
          title: "1 asf",
          id: 1,
          value:
            "gaskgjaslkgjaslkg aslgj aslkgja lksgj alksgjaslk gjasglk jasglkjgsa lk"
        },

        {
          title: " 2",
          id: 2,
          value:
            "gaskgjaslkgjaslkg aslgj aslkgja lksgj alksgjaslk gjasglk jasglkjgsa lk gaskgjaslkgjaslkg aslgj aslkgja lksgj alksgjaslk gjasglk jasglkjgsa lk"
        },

        {
          title: " 3",
          id: 3,
          value: "gaskgjaslkgjaslkg aslgj aslkgja lksgj alksgjaslk gjasglk"
        }
      ]
    };
  }
};
</script>

<style scoped>
.t-tabs {
  width: 400px;
}
</style>
